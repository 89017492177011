import * as React from 'react';
import Layout from '../layouts/Layout';
import JobAlerts from '../components/JobAlerts/JobAlerts';

const NotFoundPage: React.FC = () => {
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'Positions',
			href: '/search/',
			active: false
		},
		{
			text: 'Our Story',
			href: '/story/',
			active: false
		},
		{
			text: 'Military',
			href: '/military/',
			active: false
		},
		{
			text: 'Benefits',
			href: '/benefits/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Career Path',
			href: '/career-path/',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={'Belle Tire Careers'}
			desc={'Test'}
		>
			<div className={'container mx-auto py-20'}>
				<h1 className={'text-rubik text-center text-4xl text-bt-blue'}>
					<span className={'text-9xl text-bt-red'}>404</span> <br />{' '}
					Page Not Found.
				</h1>
			</div>
			<JobAlerts />
		</Layout>
	);
};

export default NotFoundPage;
